import * as React from 'react'

import Container from 'components/Container'
import Img from 'components/Image'
import Layout from 'components/layout'
import Seo from 'components/seo'

import building from 'images/building_icon.png'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Oooops - Looks like a 404..." />
    <Container>
      <Img xxl rounded src={building} alt="building" />
    </Container>
  </Layout>
)

export default NotFoundPage
